@import 'Colours';

/* Hero Styles */
.hero {
  background-color: rgba(176, 203, 234, 0.31);
  width: 100%;
  min-height: 33em;

  .container {
    overflow: hidden !important;
    max-width: 1400px;
    margin: 0 auto;
    justify-content: center;
  }

  .row {
    display: flex;
    flex-direction: row;
    width: auto;
  }

  .textCont {
    flex: 1;
    display: flex;
    align-items: center !important;
    margin-left: 1.06vh;
    margin-right: 1rem;
    padding-left: 0.5rem;
    padding-top: 5em;
  }

  .landingHeader {
    margin-top: 4vh;

     @media only screen and (max-width: 822px) {
      margin-top: unset;
    }
  }

  .subtitle {
    font-weight: 400;
    font-size: 1.4rem;
    color: black !important;
    justify-content: center;
  }

  .title {
    font-weight: 500;
    font-size: 3em;
    justify-content: center;
    @media screen and (max-width: 400px) {
      font-size: 2.5em;
    }
  }

  .imgCont {
    float: right;
    // width: 25em;
    max-width: 350px;
    padding: 0 0.5rem;
    padding-top: 5em;
    display: flex;
    align-items: center;
  }

  .button {
    width: 220px;
    margin-top: 2.5vh;
  }

  .registerLink {
    display:inline-block;
    padding-left: 0.3vh;
    margin-top: 1vh;
    margin-bottom: 1vh;
  }

  .background {
    overflow: hidden !important;
    z-index: -1;
    opacity: 0.2;
  }

  .backgroundCont {
    position: absolute !important;
    overflow: hidden !important;
    z-index: -1;
    padding-bottom: 2em !important;
  }
}

.stockImages {
  box-sizing: border-box;
  display: flex;
  background-color: $maple-dark-blue;
}

.stockRow {
  padding: 7px 0 0 !important;
  margin: 0 !important;
  --bs-gutter-x: 0;
  margin: 0 auto !important;
}

.benefitDescription {
  margin-left: 5px;
  @media only screen and (max-width: 767px) {
    margin-left: 0;
  }
}

.benefits {
  margin: 0 auto;
  padding-top: calc(60px + 0.8 * 1.7em);
  max-width: 1400px;
  @media (min-width: 768px) {
    padding-left: 0.5rem;
  }

  .cont {
    margin: 0;
    margin-bottom: 2em;
  }

  .title {
    padding: 0;
    margin: 0;
    font-weight: 500;
    font-size: 1.5rem;
  }

  .subtitle {
    padding: 0;
    margin: 0;
    font-size: 1rem;
  }

  .subtitle2 {
    padding: 0;
    margin: 0;
    font-size: 0.8125rem;
    opacity: 0.9;
  }

  .icon {
    --extra-width: 0rem;
    --phone-x-offset: 0rem;
    color: $maple-dark-blue;
    flex-grow: 0;
    flex-shrink: 0;
    width: calc(3rem + var(--extra-width));
    margin-right: calc(1rem - var(--extra-width) / 2);
    margin-left: calc(-1 * var(--extra-width) / 2);
    @media only screen and (min-width: 425px) and (max-width: 767px) {
      margin-left: calc(1rem - var(--extra-width) / 2);
    }
    @media only screen and (max-width: 425px) {
      margin-right: calc(1rem - var(--extra-width) / 2 - var(--phone-x-offset));
      margin-left: calc(-1 * var(--extra-width) / 2 + var(--phone-x-offset));
    }
    &#TapToPay {
      --extra-width: 1rem;
      --phone-x-offset: 0.25rem;
    }
  }
}

.monitor {
  width: 100%;
  padding-left: 3rem;
  padding-top: calc(60px + 0.8 * 1.7em);
  margin-bottom: 5em;
  display: flex;

  .row {
    margin: 0 auto !important;
  }

  .container {
    max-width: 1800px;
    margin: 0 auto;
  }

  .subtitle {
    font-weight: 400;
    font-size: 1.1875rem;
    color: black !important;
  }

  .imgCont {
    max-width: 800px;
    padding-top: 1em;
    padding-right: 6em;
    display: flex;
    align-items: center;
  }

  .title {
    margin: 0;
  }

  .icon {
    margin-right: 15px;
  }

  .checkCont {
    margin-bottom: 10px;
    color: $maple-dark-blue;
  }

  .linkP {
    padding-top: 0;
    padding-bottom: 20px;
  }

  .roadmapLink {
    font-weight: 500;
    color: $link;
  }

  .mobileHeader {
    font-weight: 600;
    padding-bottom: 5px;
  }

  .mobileHeaderLink {
    font-weight: 600;
    padding-bottom: 5px;
    color: #181a4b;
  }
}

.googleApp {
  width: 170px !important;
  padding-bottom: 8px;
}

.appleApp {
  width: 170px !important;
}

.purchase {
  background-color: rgba(176, 203, 234, 0.31);
  padding-bottom: 3em;

  .cont {
    margin: 0 auto !important;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 6em;
    max-width: 1400px;
  }

  .header {
    padding-top: 1em;
    margin-bottom: 0.2em;
  }
    
  .subheader {
    padding: 0;
    margin: 0;
    font-weight: 500;
    margin-bottom: 1.5em;
  }

  .cont {
    margin: 0;
    padding-bottom: 20px;
  }

  .title {
    padding: 0;
    margin: 0;
    font-weight: 500;
  }

  .subtitle {
    padding: 0;
    margin: 0;
    font-size: 0.9375rem;
  }

  .subtitleBold {
    padding: 0;
    margin: 0;
    font-size: 0.8125rem;
    font-weight: 700;
  }

  .icon {
    width: 50px !important;
    height: 50px !important;
    margin-right: 1vh;
    background-color: rgba(94, 109, 128, 0.397);
    border-radius: 100%;
    display: table;
  }

  .iconText {
    text-align: center;
    vertical-align: middle;
    display: table-cell;
    font-size: 1.0625rem;
    font-weight: 700;
  }

  .button {
    width: fit-content;
    margin: 0 auto;
    padding-left: 4rem;
    padding-right: 4rem;
    margin-top: 3em;
  }
}

@media only screen and (max-width: 767px) {
  .imgCont {
    display: none !important;
  }
}
